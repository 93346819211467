import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Jumbotron, Container, Image } from "react-bootstrap";
import "../styles/seo.scss";

import step1 from "../seo_images/step1.png";
import step2 from "../seo_images/step2.png";
import step3 from "../seo_images/step3.png";
import step4 from "../seo_images/step4.png";
import step1quickres from "../seo_images/step1quickres.jpeg";
import step2quickres from "../seo_images/step2quickres.png";

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout pageInfo={{ pageName: "thnkdev" }}>
      <Jumbotron fluid>
        <Container>
          <h1>{post.frontmatter.title}</h1>
          <div className="tutorialStepByStepWrapper">
            <span class="tutorialDescription">
              We understand it can be a bit annoying to have to change the
              display resolution on macOS {post.frontmatter.macOS}. <br />
              Here are some step by step instructions of how to use System
              Preferences to change the resolution on your Mac:
            </span>
            <div className="tutorialStepByStep">
              <div className="tutorialStep">
                1. Go to your macOS {post.frontmatter.macOS} desktop
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step1} fluid rounded />
                  </div>
                </div>
              </div>
              <div className="tutorialStep">
                2. Open up the System Preferences app (you can do this by
                searching for it in spotlight, or clicking the icon with the
                gears in your dock)
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step2} fluid rounded />
                  </div>
                </div>
              </div>
              <div className="tutorialStep">
                3. Click on the icon for "Displays"
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step3} fluid rounded />
                  </div>
                  <div class="tutorialStepBodyDetails">
                    You'll now see some info about the display. You can easily
                    change the brightness, but you'll see that Apple doesn't
                    give many options to change your resolution except for
                    default for display and scaled.
                  </div>
                </div>
              </div>
              <div className="tutorialStep">
                4. Now if you click any of the other resolutions from larger
                text to more space, the resolution will change
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step4} fluid rounded />
                  </div>
                </div>
              </div>
              <div className="useQuickResInsteadPromo">
                Apple doesn't allow users to go into all of the resolutions that
                your Mac can really use. If you want to unlock all the
                resolutions your Mac has to offer and change display resolutions
                in just one click, you should check out{" "}
                <a href="https://www.thnkdev.com/QuickRes">QuickRes</a>!
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <h1>{post.frontmatter.title} using QuickRes</h1>
          <div className="tutorialStepByStepWrapper">
            <span class="tutorialDescription">
              QuickRes makes it really easy to switch resolutions to{" "}
              {post.frontmatter.resolution} on your Mac. QuickRes is one of the
              best productivity tools you can install on your Mac. It makes the
              process of changing your resolution just one click away, and
              unlocks tons of new resolutions for your Mac's display. It's been
              featured on sites like{" "}
              <a href="https://lifehacker.com/quickres-sharpens-your-non-retina-mac-at-low-resolution-5957623">
                {" "}
                LifeHacker
              </a>
              ,{" "}
              <a href="https://9to5mac.com/2013/05/14/quickres-3-0-makes-switching-resolutions-on-your-retina-macbook-pro-quick-and-easy/">
                9to5Mac
              </a>
              ,{" "}
              <a href="https://www.macworld.com/article/2012693/mac-gems-quickres-helps-you-get-the-most-out-of-retina-displays.html">
                MacWorld
              </a>{" "}
              and{" "}
              <a href="https://www.producthunt.com/posts/quickres">
                ProductHunt
              </a>
              . <br /> <br />
              Here are some step by step instructions of how to use QuickRes to
              change the resolution on {post.frontmatter.macOS}:
            </span>
            <div className="tutorialStepByStep">
              <div className="tutorialStep">
                1. With QuickRes, all you have to do is click the menubar icon,
                and your resolution will change!
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step1quickres} fluid rounded />
                  </div>
                </div>
              </div>
              <div className="useQuickRes">
                If you want to unlock all the resolutions on your Mac, all you
                have to do is right-click the menubar icon and you can see all
                that QuickRes has to offer.
                <div className="tutorialStepBody">
                  <div className="stepImageWrapper">
                    <Image src={step2quickres} fluid rounded />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Jumbotron>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        resolution
        macOS
      }
    }
  }
`;
